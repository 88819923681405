<template>
  <div>
    <b-row>
      <b-col md="8">
        <b-form-group label="Alias">
          <validation-provider
            #default="{ errors }"
            name="Alias"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="UserPlusIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.alias"
                placeholder="ACH ALIAS"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Account Holder">
          <validation-provider
            #default="{ errors }"
            name="Bank Account Holder"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="ColumnsIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.accountHolder"
                placeholder="Account Holder"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Account Number">
          <validation-provider
            #default="{ errors }"
            name="Account Number"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankAccount"
                placeholder="Account Number"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank Name">
          <validation-provider
            #default="{ errors }"
            name="Bank Name"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="UserCheckIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankName"
                placeholder="Bank Name"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank Address">
          <validation-provider
            #default="{ errors }"
            name="Bank Address"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="ServerIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.bankAddress"
                placeholder="Bank Address"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Routing Number">
          <validation-provider
            #default="{ errors }"
            name="ACH Routing Number"
            rules="required"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.routingNumber"
                placeholder="Routing Number"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Country">
          <validation-provider
            #default="{ errors }"
            name="Country"
            rules="required"
          >
            <b-input-group>
              <v-select
                id="countryList"
                v-model="form.country"
                placeholder="Select country"
                style="width: 100%"
                label="name"
                :reduce="item => item.code"
                :options="countryOption"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mt-2 text-right"
      >
        <b-button
          variant="secondary"
          :disabled="isProcessing"
          @click="$emit('cancel')"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          Cancel
        </b-button>

        <b-button
          variant="primary"
          type="submit"
          class="mx-2"
          :disabled="isProcessing"
          @click.prevent="$emit('validationForm',form)"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          Save Changes
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput, BFormGroup, BRow, BCol, BButton, BInputGroup, BInputGroupPrepend, BSpinner, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BSpinner,
    vSelect,
  },
  props: {
    countryOption: {
      type: [Array, Object],
      default: () => {},
    },
    isProcessing: {
      type: [Boolean, String],
      default: () => {},
    },
    formData: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      form: {

      },
    }
  },
  mounted() {
    if (this.formData) {
      this.form = this.formData
    }
  },
}
</script>
