<template>
  <div>
    <back-with-title
      :title="walletToSplit ? `Split ${walletToSplit.description}` : `Configure ${userType === 'company' ? 'Company' : 'Your' } Accounts`"
      :callback="true"
      route-name="wallet"
      :params="userType === 'company' ? { tab: 'Company Accounts' } : {}"
      @back="() => { $emit('back') }"
    />

    <validation-observer ref="aliasValidator">
      <!-- Wallet Family Alias -->
      <validation-provider
        v-if="!walletToSplit"
        #default="{ errors }"
        name="alias"
        rules="required"
      >
        <b-form-group
          :label="$t('Alias')"
          class="w-32"
        >
          <b-form-input
            v-model="description"
            type="text"
            placeholder="Alias"
            autofocus
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>

    <!-- Wallets -->
    <label class="col-form-label mt-2">{{ $t('Accounts') }}</label>
    <b-row>
      <b-col
        v-for="form, index in forms"
        :key="index"
        md="4"
      >
        <b-card
          class="wallet-card mb-2 position-relative"
          :border-variant="form.locked ? 'success' : ''"
        >
          <b-button
            v-if="index !== 0 && (familyType === 'OPENWALLET' || isLastWallet(index))"
            v-b-tooltip.hover="$t('Remove this account')"
            variant="danger"
            class="position-absolute delete-btn"
            @click="removeForm(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
          <!-- Add New Wallet Card -->
          <div
            v-if="!form.creating"
            v-b-tooltip.hover
            :title="form.disabled ? $t('messages.save-previous-account') : ''"
            class="position-relative"
          >
            <div
              class="new-wallet-wrap text-center cursor-pointer centralize"
              :class="{ 'pe-none': form.disabled }"
              @click="() => { form.creating = true }"
            >
              <feather-icon
                icon="PlusIcon"
                size="42"
              />
            </div>
          </div>

          <!-- Create New Form -->
          <b-overlay
            v-else
            :show="isLoading"
            spinner-type="grow"
            spinner-variant="primary"
          >
            <validation-observer ref="walletForm">
              <wallet-config
                :form-data="form"
                :family-type="familyType"
                :index="index"
                :user-type="userType"
                :used-banks="unavailableBanks"
                :banks="bankingInfo"
                @share-changed="onShareChange(index)"
              />
            </validation-observer>

            <!-- Buttons -->
            <div
              v-if="!form.locked"
              class="d-flex pt-1 justify-content-end"
              :class="(index === 0 || isFinalStep(index)) && !walletToSplit ? 'justify-content-between' : 'justify-content-end'"
            >
              <b-button
                v-if="showBackButton(index)"
                variant="secondary"
                class="mr-1"
                @click="prevStep(index)"
              >
                <span v-if="!isFinalStep(index)">{{ $t('Cancel') }}</span>
                <span v-else>{{ $t('Back') }}</span>
              </b-button>
              <div>
                <b-button
                  v-if="familyType === 'OPENWALLET' && isLastWallet(index) && isFinalStep(index)"
                  variant="success"
                  class="mr-1"
                  @click="addForm(index)"
                >
                  {{ $t('Add Another') }}
                </b-button>
                <b-button
                  v-if="familyType === 'PERCENTBASED' || (isLastWallet(index) || !isFinalStep(index))"
                  variant="primary"
                  :disabled="isFinalStep(index) ? walletToSplit && index === 0 && Number(form.sharePercent) === initialSplitPercent : form.shareType === 'BANKWALLET' && !form.informationId"
                  @click="nextStep(index)"
                >
                  <span v-if="familyType === 'PERCENTBASED'">{{ isFinalStep(index) ? $t('Save') : $t('Next') }}</span>
                  <span v-else>{{ isFinalStep(index) ? $t('Save') : $t('Next') }}</span>
                </b-button>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <!-- Wallet Split Confirmation Modal -->
    <confirm-modal
      v-if="walletToSplit"
      modal="split-wallet-modal"
      :title="`Split ${walletToSplit.description}`"
      :alert="walletToSplit.sharedTo ? $t('messages.split-revoke-info') : ''"
      alert-variant="danger"
      icon-size="48"
      :description="`Are you sure you want to split ${walletToSplit.description} into these accounts?`"
      :params="{ index: selectedIndex }"
      @confirm="params => handleSubmit(params.index)"
    />
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BRow, BCol, BCard, BButton, BOverlay, BFormGroup, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import {
  ValidationObserver, ValidationProvider, extend, validate,
} from 'vee-validate'
import { max_value, numeric } from 'vee-validate/dist/rules'
import { required } from '@validations'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import ConfirmModal from '../reusables/ConfirmModal.vue'
import WalletConfig from './WalletConfig.vue'

extend('numeric', numeric)
extend('percent', value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return 'Share percent should be a number'
  if (value >= 0 && value <= 100) return true
  return 'Share percent should be between 0 and 100'
})

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    ConfirmModal,
    BackWithTitle,
    WalletConfig,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    walletToSplit: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    isDefaultWallet: {
      type: Boolean,
      default: () => false,
    },
    parentWallet: {
      type: String,
      default: () => '',
    },
    familyType: {
      type: String,
      default: () => 'PERCENTBASED',
    },
  },
  data() {
    return {
      required,
      max_value,
      description: '',
      forms: [{
        description: '',
        shareType: 'INTERNALWALLET',
        sharePercent: 100,
        billingInfo: null,
        availablePercent: 100,
        currentStep: 0,
        locked: false,
        creating: false,
        informationId: null,
        disabled: false,
      }],
      confirmed: 1,
      shareTypes: [
        { text: 'Alt Account', value: 'INTERNALWALLET' },
        { text: 'Bank Account', value: 'BANKWALLET' },
        { text: 'Payroll Account', value: 'PAYROLLWALLET' },
      ],
      steps: ['wallet-selection', 'details'],
      bankingInfo: {},
      isLoading: false,
      availablePercent: 100,
      initialSplitPercent: 0,
      selectedIndex: null,
    }
  },
  computed: {
    availableShareTypes() {
      return this.shareTypes.filter(type => type.value !== 'PAYROLLWALLET')
    },
    unavailableBanks() {
      return [
        ...this.usedBanks,
        ...this.forms.filter(form => form.shareType === 'BANKWALLET' && form.informationId).map(form => ({
          id: form.informationId,
          alias: form.billingInfo?.description,
        })),
      ]
    },
    isDefaultCompanyWallet() {
      return this.userType === 'company' && this.isDefaultWallet
    },
  },
  watch: {
    walletToSplit: {
      handler(val) {
        if (val) {
          if (this.isDefaultCompanyWallet) {
            this.forms[0].currentStep = 1
            this.forms[0].shareType = 'PAYROLLWALLET'
          }
          this.availablePercent = val.sharePercent
          this.initialSplitPercent = val.sharePercent
          const bankInfo = val.wallet.information

          this.forms[0].description = val.description
          this.forms[0].availablePercent = val.sharePercent
          this.forms[0].sharePercent = val.sharePercent
          this.forms[0].shareType = val.wallet.__typename
          this.forms[0].creating = true
          this.forms[0].currentStep = 1
          if (bankInfo) {
            this.forms[0].informationType = bankInfo.__typename.substr(0, bankInfo.__typename.length - 11).toUpperCase()
            this.forms[0].informationId = Number(bankInfo.id)
            this.forms[0].billingInfo = bankInfo.alias
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.bankingInfo.data) this.getBankingInfo()
  },
  methods: {
    isLastWallet(index) {
      return index === this.forms.length - 1
    },
    isFinalStep(index) {
      return this.steps[this.forms[index].currentStep] === 'details'
    },
    nextStep(index) {
      if (this.walletToSplit && index === 0 && this.forms[index].sharePercent === this.availablePercent) return
      if (!this.isFinalStep(index)) {
        this.forms[index].currentStep += 1
      } else if (this.walletToSplit && this.isLastWallet(index)) {
        this.$refs.walletForm[index].validate().then(success => {
          if (success) {
            this.selectedIndex = index
            this.$nextTick(() => {
              this.$bvModal.show('split-wallet-modal')
            })
          }
        })
      } else this.handleSubmit(index)
    },
    prevStep(index) {
      if (this.isFinalStep(index)) this.forms[index].currentStep -= 1
      else {
        this.clearForm()
      }
    },
    clearForm() {
      this.forms = [{
        shareType: this.isDefaultCompanyWallet ? 'PAYROLLWALLET' : 'INTERNALWALLET',
        sharePercent: this.walletToSplit ? this.walletToSplit.sharePercent : 100,
        billingInfo: null,
        availablePercent: this.walletToSplit ? this.walletToSplit.sharePercent : 100,
        currentStep: this.isDefaultCompanyWallet ? 1 : 0,
        informationId: null,
        locked: false,
        disabled: false,
        creating: false,
      }]
    },
    getBankingInfo() {
      let api = 'getMyBillingInfo'
      let res = 'me'
      let params = {}

      if (this.userType === 'company') {
        api = 'getCompanyBillingInfo'
        res = 'company'
        params = {
          companyUid: this.$store.state.project.selectedCompany,
        }
      }

      useApollo[this.userType][api](params).then(response => {
        this.bankingInfo = response.data[res]?.extraInformations
      })
    },
    handleSubmit(index) {
      this.$refs.walletForm[index].validate().then(success => {
        if (success) {
          if (!this.isLastWallet(index)) {
            this.forms[index].locked = true
            this.forms[index + 1].disabled = false
            this.confirmed += 1
          } else if (this.familyType === 'OPENWALLET' || this.confirmed === this.forms.length) {
            this.$refs.aliasValidator.validate().then(valid => {
              if (valid) {
                this.isLoading = true
                // Config User Wallet
                let api = 'configUserWallet'
                const params = {
                  description: this.description,
                  nonDefaultWallet: !this.isDefaultWallet,
                  input: this.forms.map(form => ({
                    sharePercent: Number(form.sharePercent),
                    description: form.description,
                    shareType: form.shareType.toUpperCase(),
                    ...(form.shareType === 'BANKWALLET'
                      ? {
                        informationId: form.informationId,
                        informationType: form.informationType,
                      } : []),
                  })),
                  familyType: this.familyType,
                }

                // Config Company Wallet
                if (this.userType === 'company') {
                  api = 'configCompanyWallet'
                  params.companyUid = this.$store.state.project.selectedCompany
                }

                // Split Wallet
                if (this.walletToSplit) {
                  api = this.userType === 'company' ? 'splitCompanyWallet' : 'splitUserWallet'
                  params.wallet = {
                    walletUid: this.parentWallet,
                    shareUid: this.walletToSplit.uuid,
                  }
                }

                useApollo[this.userType][api](params).then(response => {
                  this.showSuccessMessage({
                    data: {
                      message: response.data[api].message,
                    },
                  })
                }).catch(error => {
                  this.showErrorMessage(error)
                }).finally(() => {
                  this.$emit('wallet-config-success')
                  this.isLoading = false
                })
              }
            })
          }
        }
      })
    },
    removeForm(index, confirmed = true, addToPrevious = true) {
      // add the share percent used in the removed wallet to the availabe percent
      this.availablePercent += Number(this.forms[index - 1].sharePercent)

      if (addToPrevious) {
        // add the share percent used in the removed wallet to the previous wallet by default
        this.forms[index - 1].sharePercent = Number(this.forms[index - 1].sharePercent) + Number(this.forms[index].sharePercent)
        this.forms[index - 1].locked = false
      }

      if (confirmed) this.confirmed -= 1
      this.forms.splice(index, 1)
    },
    getAvailabeShares(index) {
      return Number(this.forms.find((form, i) => i === index).sharePercent)
    },
    getUsedShares(index) {
      return this.forms.filter((form, i) => i !== index).reduce((acc, cv) => (acc + Number(cv.sharePercent)), 0)
    },
    onShareChange(index) {
      if (this.forms[index].sharePercent <= this.forms[index].availablePercent) {
        validate(this.forms[index].sharePercent, `required|min_value:1|percent|numeric|max_value:${this.forms[index].availablePercent}`).then(result => {
          if (result.valid) {
            // add all the share percent in the forms
            const usedShare = this.forms.reduce((acc, cv) => acc + Number(cv.sharePercent), 0)
            // check if the total share percent is less than 100%
            if (usedShare > 0) {
              // if the current wallet is the last wallet, create a new wallet
              if (index === this.forms.length - 1) {
                this.availablePercent -= this.getAvailabeShares(index)
                this.addForm(index)
              } else {
                // else, user has modified the share percent after a new wallet has been pushed already
                // change the new wallet's share percent, instead of creating another new wallet
                this.availablePercent = (this.walletToSplit ? this.walletToSplit.sharePercent : 100) - this.getUsedShares(index + 1)

                if (this.availablePercent === 0 && index === this.forms.length - 2) {
                  this.removeForm(this.forms.length - 1, false, false)
                } else {
                  this.forms[index + 1].sharePercent = this.availablePercent
                  this.forms[index + 1].availablePercent = this.availablePercent
                }
              }
            }
          }
        })
      }
    },
    addForm(index) {
      if (this.familyType === 'PERCENTBASED') {
        this.forms.push({
          shareType: this.isDefaultCompanyWallet ? 'PAYROLLWALLET' : 'INTERNALWALLET',
          billingInfo: null,
          currentStep: this.isDefaultCompanyWallet ? 1 : 0,
          informationId: 0,
          locked: false,
          sharePercent: this.availablePercent,
          availablePercent: this.availablePercent,
          creating: false,
          disabled: true,
        })
      } else {
        this.$refs.walletForm[index].validate().then(success => {
          if (success) {
            this.forms[index].locked = true
            this.forms.push({
              shareType: 'INTERNALWALLET',
              billingInfo: null,
              currentStep: 0,
              informationId: 0,
              locked: false,
              creating: true,
              sharePercent: 100,
            })
          }
        })
      }
    },
    showBackButton(index) {
      if (this.familyType === 'PERCENTBASED') {
        if (this.walletToSplit) {
          if (index !== 0 && this.isFinalStep(index)) return true
        } else if (index === 0 || this.isFinalStep(index)) return true
      } else if (this.isLastWallet(index) && (index === 0 || this.isFinalStep(index))) return true
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
.radio-buttons label {
  padding: 0;
}
.delete-btn {
  top: 20px;
  right: 20px;
  z-index: 1;
}
</style>
