<template>
  <validation-observer ref="walletForm">
    <!-- Wallet Selection -->
    <b-form-group
      v-if="shareTypes && steps[form.currentStep] === 'wallet-selection'"
      :label="$t('Select Account Type')"
    >
      <b-form-radio-group
        v-model="form.shareType"
        button-variant="outline-primary"
        class="w-100 radio-buttons"
        buttons
      >
        <b-form-radio
          v-for="option in availableShareTypes"
          :key="option.value"
          v-model="form.shareType"
          class="my-1"
          name="wallet-type"
          :value="option.value"
          :disabled="form.locked && familyType === 'PERCENTBASED'"
        >
          <div
            v-if="option.value !== 'PAYROLLWALLET'"
            class="p-1"
            @click="onShareTypeChange"
          >
            <b-avatar
              :size="68"
              class="mx-auto"
            >
              <i :class="option.value == 'INTERNALWALLET' ? 'fa fa-32 fa-money-bill-1' : 'fa fa-32 fa-university'" />
            </b-avatar>
            <h5 class="mt-1">
              {{ option.text }}
            </h5>
          </div>
        </b-form-radio>
      </b-form-radio-group>

      <small
        v-if="selectBankError"
        class="text-danger float-right cursor-pointer"
        @click="() => { $bvModal.show(`select-banking-info-${index}-${userType}`) }"
      >
        <feather-icon icon="AlertCircleIcon" />
        {{ selectBankError }}
      </small>
    </b-form-group>

    <!-- Details -->
    <div v-else-if="shareTypes && steps[form.currentStep] === 'details'">
      <div
        class="d-flex mb-1"
        :class="form.shareType === 'BANKWALLET' ? 'align-items-start': 'align-items-center'"
      >
        <div>
          <i
            :class="getWalletIcon(form.shareType)"
            class="f-32"
          />
        </div>
        <div class="mx-1 mb-0">
          <h4 class="mb-0">
            {{ shareTypes.find(type => type.value === form.shareType.toUpperCase()).text }}
          </h4>
          <p
            v-if="form.shareType === 'BANKWALLET'"
            class="mb-0"
          >
            {{ form.billingInfo }}
          </p>
        </div>
      </div>

      <validation-provider
        #default="{ errors }"
        name="alias"
        rules="required"
      >
        <b-form-group :label="$t('Alias')">
          <b-form-input
            v-model="form.description"
            rows="4"
            placeholder="Alias"
            :disabled="form.locked"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-if="familyType === 'PERCENTBASED'"
        #default="{ errors }"
        name="share percent"
        :rules="`required|min_value:1|percent|numeric|max_value:${form.availablePercent}`"
      >
        <div class="mb-2">
          <b-input-group
            :prepend="`${$t('Share Percent')}:`"
            append="%"
          >
            <b-form-input
              v-model="form.sharePercent"
              placeholder="Share Percent"
              type="number"
              :disabled="form.locked"
              @input="val => { form.sharePercent = val; $emit('share-changed') }"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </validation-provider>
    </div>

    <select-banking-info
      v-if="form.shareType === 'BANKWALLET'"
      :index="index"
      :banking-info="availableBanks.data"
      :user-type="userType"
      @payment-select="data => { setBillingInfo(data) }"
      @refetch="getBankingInfo"
    />
  </validation-observer>
</template>

<script>
import {
  BFormGroup, BFormRadioGroup, BFormRadio, BAvatar, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { max_value, numeric } from 'vee-validate/dist/rules'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import { mapGetters } from 'vuex'
import SelectBankingInfo from '../reusables/SelectBankingInfo.vue'

const { getWalletIcon } = useWallet()

extend('numeric', numeric)
extend('percent', value => {
  if (value >= 0 && value <= 100) return true
  return 'Share percent should be between 0 and 100'
})

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BInputGroup,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
    SelectBankingInfo,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    familyType: {
      type: String,
      default: () => 'PERCENTBASED',
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    banks: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      max_value,
      getWalletIcon,
      form: {
        description: '',
        shareType: 'INTERNALWALLET',
        sharePercent: 100,
        billingInfo: null,
        availablePercent: 100,
        currentStep: 0,
        locked: false,
        creating: false,
        informationId: null,
        disabled: false,
      },
      steps: ['wallet-selection', 'details'],
      shareTypes: [
        { text: 'Alt Account', value: 'INTERNALWALLET' },
        { text: 'Bank Account', value: 'BANKWALLET' },
        { text: 'Payroll Account', value: 'PAYROLLWALLET' },
      ],
      selectBankError: '',
      bankingInfo: {},
      permissions: ['company.edit', 'accounts.company'],
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany']),
    availableShareTypes() {
      const types = this.shareTypes.filter(type => type.value !== 'PAYROLLWALLET')
      if (this.userType === 'users' || this.canEditCompany) return types
      return types.filter(type => type.value === 'INTERNALWALLET')
    },
    availableBanks() {
      if (this.bankingInfo.data) {
        let bankingInfo = this.bankingInfo.data
        if (this.usedBanks) {
          bankingInfo = bankingInfo.filter(info => this.usedBanks.findIndex(
            bank => bank.id === info.information.id,
          ) === -1)
        }

        return {
          data: bankingInfo,
          total: bankingInfo.length,
        }
      }
      return this.bankingInfo
    },
  },
  watch: {
    formData: {
      handler(val) {
        if (val) {
          this.form = val
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getBankingInfo()
  },
  methods: {
    onShareTypeChange() {
      setTimeout(() => {
        if (this.form.shareType === 'BANKWALLET') {
          if (this.availableBanks.total) {
            this.$bvModal.show(`select-banking-info-${this.index}-${this.userType}`)
            this.selectBankError = ''
          } else this.selectBankError = 'Click here to configure a new banking information!'
        } else this.selectBankError = ''
      }, 300)
    },
    setBillingInfo(data) {
      if (data) {
        const selectedBanking = this.bankingInfo.data.find(info => info.information.id === data).information
        const infoType = selectedBanking.__typename
        this.form.informationType = infoType.substr(0, infoType.length - 11).toUpperCase()

        this.form.informationId = selectedBanking.id
        this.form.billingInfo = selectedBanking.alias
        this.form.currentStep += 1
      }
    },
    getBankingInfo() {
      if (this.banks) this.bankingInfo = this.banks
      else {
        let api = 'getMyBillingInfo'
        let res = 'me'
        let params = {}

        if (this.userType === 'company') {
          api = 'getCompanyBillingInfo'
          res = 'company'
          params = {
            companyUid: this.$store.state.project.selectedCompany,
          }
        }

        useApollo[this.userType][api](params).then(response => {
          this.bankingInfo = response.data[res]?.extraInformations
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
.radio-buttons label {
  padding: 0;
}
</style>
