<template>
  <div>
    <!-- Split Wallet -->
    <create-split-wallet
      v-if="splitting"
      :wallet-to-split="selectedWallet"
      :parent-wallet="selectedParentWallet"
      :user-type="userType"
      :used-banks="usedBanks"
      @back="() => { splitting = false }"
      @wallet-config-success="onWalletActionSuccess"
    />

    <!-- Merge Wallets -->
    <merge-wallets
      v-else-if="merging && shareNumberOfSelectedFamily > 1"
      :merge-into="selectedWallet"
      :parent-wallet="selectedParentWallet"
      :wallets="getWalletShares"
      :share-types="shareTypes"
      :user-type="userType"
      @back="() => { merging = false }"
      @wallet-merge-success="onWalletActionSuccess"
    />

    <!-- Show Wallets -->
    <div v-else>
      <back-with-title
        :title="userType === 'company' ? `${company.name}${$t('s Accounts')}` : $t('Your Accounts')"
        route-name="wallet"
        :params="{ fam: currentFam, tab: userType === 'company' ? 'Company Accounts' : '' }"
      />
      <b-tabs
        pills
        class="alt-family"
      >
        <b-tab
          v-for="walletFam, i in walletFamily"
          :key="walletFam.uuid"
          :title="walletFam.description"
          :active="(createdNewFamily && i === walletFamily.length - 1)
            || ($route.params.fam && $route.params.fam === walletFam.uuid)"
          @click="() => { currentFam = walletFam.uuid }"
        >
          <!-- Tab Title with Three Dots -->
          <template #title>
            <div
              class="d-flex align-items-center"
              @mouseover="currentFam = i"
              @mouseleave="currentFam = null"
            >
              <p class="mb-0">
                <feather-icon
                  v-if="isWalletShared(walletFam.shares[0], userType)"
                  v-b-tooltip.hover="`${'Shared by'} ${walletFam.shares[0].owner.name}`"
                  icon="LinkIcon"
                />
                {{ walletFam.description }}
              </p>

              <b-dropdown
                no-caret
                class="alt-family-more"
                variant="link"
                size="sm"
              >
                <template #button-content>
                  <feather-icon
                    class="ml-1"
                    :class="{'no-opacity': currentFam !== i}"
                    icon="MoreVerticalIcon"
                  />
                </template>

                <b-dropdown-item @click="showEditModal(walletFam)">
                  <feather-icon icon="Edit2Icon" />
                  {{ $t('Rename') }}
                </b-dropdown-item>
              </b-dropdown>

            </div>
          </template>

          <!-- Show Wallets -->
          <b-row class="mt-4">
            <b-col
              v-for="share, index in walletFam.shares"
              :key="share.uuid"
              md="4"
            >
              <!-- Withdraw Fund Card -->
              <withdraw-fund
                v-if="withdrawing && selectedWallet.uuid === share.uuid"
                :wallet="share"
                :parent-wallet="selectedParentWallet"
                :user-type="userType"
                :charges="charges"
                @back="() => { withdrawing = false }"
                @withdraw-success="onWalletActionSuccess"
              />

              <!-- Transfer Fund Card -->
              <transfer-fund
                v-else-if="transfering && selectedWallet.uuid === share.uuid"
                :wallet="share"
                :wallet-uid="selectedParentWallet"
                :available-wallets="availableWallets"
                :available-family="availableFamily"
                :user-type="userType"
                @back="() => { transfering = false }"
                @transfer-success="onWalletActionSuccess"
              />

              <!-- Share Wallets -->
              <share-wallet
                v-else-if="sharing && selectedWallet.uuid === share.uuid"
                :wallet="selectedWallet"
                :parent-wallet="selectedParentWallet"
                :user-type="userType"
                @back="() => { sharing = false }"
                @share-success="onWalletActionSuccess"
              />

              <!-- Show Wallet Card -->
              <wallet-card
                v-else
                class="mb-2"
                :share="share"
                :share-types="shareTypes"
                :parent-wallet="walletFam.uuid"
                :wallet-family-count="walletFamily.length"
                :total-wallets="walletFam.shares.length"
                :user-type="userType"
                :used-banks="usedBanks"
                :index="Number(`{i}${index})`)"
                :is-default="walletFam.isDefault"
                @action="(action, wallet, parentWallet) => { walletAction(action, wallet, parentWallet)}"
                @update="alias => { $emit('wallet-updated', { alias, uuid: share.uuid, walletFam: walletFam.uuid }) }"
                @refetch="$emit('refetch-wallet')"
              />
            </b-col>
          </b-row>
        </b-tab>
        <template #tabs-end>
          <b-button
            variant="primary"
            class="ml-2"
            @click="$emit('create-wallet-fam')"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </b-tabs>
    </div>

    <!-- Edit Wallet Family Alias -->
    <b-modal
      v-if="selectedWalletFam"
      id="edit-fam-details-modal"
      :title="$t('Rename Account')"
      hide-footer
    >
      <edit-wallet-alias
        :wallet-fam="selectedWalletFam"
        :user-type="userType"
        wallet-type="family"
        :no-title="true"
        @cancel="() => { $bvModal.hide('edit-fam-details-modal')}"
        @wallet-updated="alias => onWalletFamilyUpdated(alias)"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BTabs, BTab, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import useWallet from '@/utils/wallet'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'
import MergeWallets from './WalletActions/MergeWallets.vue'
import WalletCard from './reusables/WalletCard.vue'
import WithdrawFund from './WalletActions/WithdrawFund.vue'
import TransferFund from './WalletActions/TransferFund.vue'
import EditWalletAlias from './WalletActions/EditWalletAlias.vue'
import ShareWallet from './WalletActions/ShareWallet.vue'

const { isWalletShared } = useWallet()

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BDropdown,
    BDropdownItem,
    CreateSplitWallet,
    BackWithTitle,
    MergeWallets,
    WalletCard,
    WithdrawFund,
    TransferFund,
    EditWalletAlias,
    ShareWallet,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    wallets: {
      type: Object,
      default: () => [],
    },
    walletFamily: {
      type: Array,
      default: () => [],
    },
    shareTypes: {
      type: Array,
      default: () => [],
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    createdNewFamily: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isWalletShared,
      self: getUserData(),
      selectedWallet: null,
      selectedParentWallet: null,
      splitting: false,
      merging: false,
      withdrawing: false,
      transfering: false,
      sharing: false,
      charges: {},
      company: this.$store.state.project.project.company,
      availableFamily: null,
      currentFam: null,
      createWalletFam: false,
      selectedWalletFam: null,
    }
  },
  computed: {
    availableWallets() {
      const wallets = this.wallets.shares.filter(wallet => wallet.uuid !== this.selectedWallet.uuid).map(wallet => ({
        uuid: wallet.uuid,
        label: wallet.description,
      }))
      return [
        { label: 'Select an Account', uuid: null },
        ...wallets,
      ]
    },
    getWalletShares() {
      return this.walletFamily.find(fam => fam.uuid === this.selectedParentWallet).shares
    },
    shareNumberOfSelectedFamily() {
      if (this.selectedParentWallet) {
        return this.walletFamily.find(family => family.uuid === this.selectedParentWallet).shares.length
      }
      return 0
    },
  },
  mounted() {
    this.currentFam = this.$route.params.fam ?? this.walletFamily[0]?.uuid
    this.availableFamily = this.$route.params.availableFamily ?? this.getWalletFamily()
  },
  methods: {
    walletAction(action, wallet, parentWallet) {
      if (action === 'scheduling') {
        this.$router.push({ name: 'scheduled-transfers', params: { wallet, parentWallet, availableFamily: this.availableFamily } })
      } else if (action === 'withdrawing' && !this.charges.length) this.getCompanyCharges()
      const actions = ['splitting', 'merging', 'withdrawing', 'transfering', 'sharing']
      actions.forEach(act => {
        this[act] = false
      })
      this.selectedWallet = wallet
      this.selectedParentWallet = parentWallet
      this.$nextTick(() => {
        this[action] = true
      })
    },
    onWalletActionSuccess() {
      this.splitting = false
      this.merging = false
      this.withdrawing = false
      this.transfering = false
      this.sharing = false
      this.$emit('refetch-wallet')
    },
    getCompanyCharges() {
      useApollo.company.getBillingCharges().then(response => {
        this.charges = response.data.workzone.extraInformations?.data[0]?.information
        if (!this.charges) this.charges = {}
      })
    },
    getWalletFamily() {
      let api = 'getWalletFamilies'
      const params = {}

      if (this.userType === 'company') {
        api = 'getCompanyWalletFamilies'
        params.companyUid = this.company.uuid
      }
      const family = [
        { uuid: null, label: 'Select an Account' },
      ]
      useApollo[this.userType][api](params).then(response => {
        const families = this.userType === 'company' ? response.data.me.companies.data[0].wallets?.data : response.data.me.wallets?.data
        let excludeSharedWithInbound = families
        if (this.userType === 'users') {
          excludeSharedWithInbound = families.filter(wallet => !this.isWalletShared(wallet.shares[0], this.userType) || (this.isWalletShared(wallet.shares[0], this.userType) && wallet.shares[0].shareDirection !== 'OUTBOUND'))
        }
        family.push(...excludeSharedWithInbound.map(fam => ({
          uuid: fam.uuid,
          label: fam.description,
        })))
      })
      return family
    },
    showEditModal(walletFam) {
      this.selectedWalletFam = walletFam

      this.$nextTick(() => {
        this.$bvModal.show('edit-fam-details-modal')
      })
    },
    onWalletFamilyUpdated(alias) {
      this.walletFamily.find(fam => fam.uuid === this.selectedWalletFam.uuid).description = alias
      this.$bvModal.hide('edit-fam-details-modal')
      this.selectedWalletFam = null
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/wallet-page.scss";
</style>
