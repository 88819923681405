<template>
  <div>
    <back-with-title
      :title="`Merge Accounts Into ${mergeInto.description}`"
      :callback="true"
      @back="$emit('back')"
    />

    <b-card v-if="!walletsToMerge.length">
      <p class="mb-0 text-center">
        {{ $t('No accounts to merge') }}
      </p>
    </b-card>

    <div v-else>
      <h5>Select the accounts to merge into {{ mergeInto.description }}</h5>
      <b-overlay :show="isLoading">
        <b-row>
          <b-col
            v-for="wallet in walletsToMerge"
            :key="wallet.uuid"
            md="4"
            class="cursor-pointer position-relative"
            @click="selectWallet(wallet)"
          >
            <wallet-card
              class="mb-2"
              :class="isSelected(wallet.uuid) ? 'border-success' : 'border'"
              :share="wallet"
              :share-types="shareTypes"
              :user-type="userType"
              no-buttons
            />
            <feather-icon
              v-if="isSelected(wallet.uuid)"
              class="selected-icon position-absolute"
              icon="CheckIcon"
              size="32"
              color="#28c76f"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <div class="d-flex float-right">
      <b-button
        v-if="form.walletUids.length"
        v-b-modal.merge-wallets-modal
        variant="primary"
        class="mb-2 mr-1"
        :disabled="isLoading"
      >
        <i class="rotate-180 fa fa-project-diagram" />
        {{ $t('Merge') }}
      </b-button>
      <b-button
        variant="secondary"
        class="mb-2"
        @click="$emit('back')"
      >
        {{ $t('Close') }}
      </b-button>
    </div>

    <!-- Confirm Modal -->
    <confirm-modal
      modal="merge-wallets-modal"
      :title="$t('Merge Accounts')"
      alert-variant="danger"
      :alert="hasSharing.length ? $t('messages.merge-revoke-info') : ''"
      icon-size="48"
      :description="$t('messages.merge-confirmation')"
      @confirm="mergeWallets"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BOverlay, BCard,
} from 'bootstrap-vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import useApollo from '@/plugins/graphql/useApollo'
import WalletCard from '../reusables/WalletCard.vue'
import ConfirmModal from '../reusables/ConfirmModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    BackWithTitle,
    WalletCard,
    ConfirmModal,
  },
  props: {
    mergeInto: {
      type: Object,
      default: () => {},
    },
    wallets: {
      type: Array,
      default: () => [],
    },
    shareTypes: {
      type: Array,
      default: () => [],
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    parentWallet: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: {
        destinationWalletUid: null,
        walletUids: [],
      },
      isLoading: false,
    }
  },
  computed: {
    walletsToMerge() {
      return this.wallets.filter(wallet => wallet.uuid !== this.mergeInto.uuid)
    },
    hasSharing() {
      return this.form.walletUids.filter(uuid => this.wallets.find(wallet => wallet.uuid === uuid).sharedTo)
    },
  },
  mounted() {
    if (this.mergeInto) this.form.destinationWalletUid = this.mergeInto.uuid
  },
  methods: {
    isSelected(uuid) {
      return this.form.walletUids.indexOf(uuid) !== -1
    },
    selectWallet(wallet) {
      const indexOfSelectedWallet = this.form.walletUids.indexOf(wallet.uuid)
      if (indexOfSelectedWallet === -1) {
        this.form.walletUids.push(wallet.uuid)
      } else this.form.walletUids.splice(indexOfSelectedWallet, 1)
    },
    mergeWallets() {
      this.isLoading = true
      let api = 'mergeUserWallets'
      const params = {
        destination: {
          walletUid: this.parentWallet,
          shareUid: this.form.destinationWalletUid,
        },
        shareUids: this.form.walletUids,
      }

      if (this.userType === 'company') {
        api = 'mergeCompanyWallets'
        params.companyUid = this.$store.state.project.selectedCompany
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('wallet-merge-success')
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.selected-icon {
  top: 12px;
  right: 32px;
}
</style>
