<template>
  <b-overlay :show="isLoading">
    <h4
      v-if="!noTitle"
      class="mb-1"
    >
      {{ $t('Rename Account') }}
    </h4>
    <validation-observer ref="walletEditForm">
      <validation-provider
        #default="{ errors }"
        name="alias"
        rules="required"
      >
        <b-form-group :label="$t('Alias')">
          <b-form-input
            v-model="form.description"
            type="text"
            placeholder="Alias"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mx-1"
          @click="() => { $emit('cancel') }"
        >
          {{ $t('Cancel') }}
        </b-button>

        <b-button
          variant="primary"
          @click="updateWallet"
        >
          {{ $t('Rename') }}
        </b-button>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BFormGroup, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    wallet: {
      type: Object,
      default: () => {},
    },
    walletFam: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    noTitle: {
      type: Boolean,
      default: () => false,
    },
    walletType: {
      type: String,
      default: () => 'wallet',
    },
  },
  data() {
    return {
      required,
      form: {},
      isLoading: false,
    }
  },
  mounted() {
    if (this.wallet) {
      const { description, walletUid, shareUid } = this.wallet
      this.form = {
        wallet: {
          walletUid,
          shareUid,
        },
        description,
      }
    }
    if (this.walletFam) {
      const { description, uuid } = this.walletFam
      this.form = {
        walletUid: uuid,
        description,
      }
    }
  },
  methods: {
    updateWallet() {
      this.$refs.walletEditForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          let api = this.walletType === 'wallet' ? 'updateUserWalletShareDetails' : 'updateUserWalletDetails'
          let params = this.form

          if (this.userType === 'company') {
            api = this.walletType === 'wallet' ? 'updateCompanyWalletShareDetails' : 'updateCompanyWalletDetails'
            params = {
              ...params,
              companyUid: this.$store.state.project.selectedCompany,
            }
          }

          useApollo[this.userType][api](params).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data[api].message,
              },
            })
          }).finally(() => {
            this.isLoading = false
            this.$emit('wallet-updated', this.form.description)
          })
        }
      })
    },
  },
}
</script>
