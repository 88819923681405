<template>
  <b-overlay
    :show="isLoading"
    class="wallet-share"
  >
    <b-card>
      <h5 v-if="!isModal">
        {{ $t('Share Account') }}
      </h5>

      <validation-observer ref="shareAccountForm">
        <!-- User Type  -->
        <validation-provider v-if="userType === 'company'">
          <b-form-group
            :label="$t('Share To')"
            class="d-flex justify-content-between"
          >
            <b-form-radio-group
              v-model="sharedTo"
              :options="sharedToOptions"
              value-field="value"
              text-field="text"
            />
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider
          #default="{ errors }"
          name="email"
          rules="required|email"
        >
          <b-form-group :label="$t('Email')">
            <b-form-input
              v-model="form.email"
              type="text"
              placeholder="Email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Remarks -->
        <validation-provider
          #default="{ errors }"
          name="remarks"
          rules="required"
        >
          <b-form-group :label="$t('Remarks')">
            <b-form-input
              v-model="form.remarks"
              type="text"
              placeholder="Remarks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Rights -->
        <validation-provider
          #default="{ errors }"
          name="right"
          rules="required"
        >
          <radio-form
            v-model="form.rights"
            :label="$t('Rights')"
            name="rights"
            :options="rightsOptions"
            selected="BOTH"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>

      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          @click="() => { $emit('back') }"
        >
          {{ $t('Cancel') }}
        </b-button>

        <b-button
          variant="primary"
          class="ml-1"
          @click="onShare"
        >
          <i class="fa fa-share" />
          {{ $t('Share') }}
        </b-button>
      </div>
    </b-card>

    <confirm-modal
      :title="`${$t('Share Account')} - ${wallet.description}`"
      :modal="`wallet-share-modal-${wallet}`"
      :description="`Are you sure you want to share '${wallet.description}' account with ${form.email}?`"
      @cancel="$bvModal.hide(`wallet-share-modal-${wallet}`)"
      @confirm="shareWallet"
    />
  </b-overlay>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BButton, BOverlay, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import useApollo from '@/plugins/graphql/useApollo'
import RadioForm from '@/views/common/components/RadioForm.vue'
import ConfirmModal from '../reusables/ConfirmModal.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormRadioGroup,
    ValidationObserver,
    ValidationProvider,
    ConfirmModal,
    RadioForm,
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    },
    parentWallet: {
      type: String,
      required: true,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    isModal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      required,
      email,
      form: {
        rights: 'BOTH',
        isCompany: false,
      },
      rightsOptions: [
        { label: 'Both', value: 'BOTH', details: this.$t('messages.both-share-right-info') },
        { label: 'Out-Bound', value: 'OUTBOUND', details: this.$t('messages.outbound-share-right-info') },
        { label: 'In-Bound', value: 'INBOUND', details: this.$t('messages.inbound-share-right-info') },
      ],
      sharedToOptions: [
        { text: 'User', value: 'user' },
        { text: 'Company', value: 'company' },
      ],
      sharedTo: 'user',
      isLoading: false,
    }
  },
  methods: {
    onShare() {
      this.$refs.shareAccountForm.validate().then(success => {
        if (success) this.$bvModal.show(`wallet-share-modal-${this.wallet}`)
      })
    },
    shareWallet() {
      this.isLoading = true
      if (this.sharedTo === 'company') this.form.isCompany = true
      else this.form.isCompany = false

      let api = 'inviteForWalletShare'
      const params = {
        wallet: {
          walletUid: this.parentWallet,
          shareUid: this.wallet.uuid,
        },
        input: this.form,
      }

      if (this.userType === 'company') {
        api = 'inviteForCompanyWalletShare'
        params.companyUid = this.$store.state.project.selectedCompany
      }

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
        this.$emit('share-success')
        this.form = {
          rights: 'BOTH',
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
.wallet-share {
  .custom-control-inline {
    margin-right: 64px !important;
  }
}
</style>
