<template>
  <div>
    <b-card>
      <h5 v-if="!isModal">
        Transfer Fund From {{ wallet.description }}
      </h5>
      <h4 class="mb-2">
        {{ $t('Available Balance') }}: {{ formatAmount(wallet.balance ) }}
      </h4>

      <b-overlay :show="isProcessing">
        <validation-observer ref="transferFundForm">

          <div class="d-flex align-items-baseline mb-2">
            <b-form-checkbox
              id="isEntireFamily"
              v-model="isEntireFamily"
              class="colorful-switch"
              switch
              @input="resetWallet"
            />
            <label
              for="isEntireFamily"
              class="cursor-pointer f-16"
            >
              {{ isEntireFamily ? $t('messages.transfer-family') : $t('messages.transfer-account') }}
            </label>
          </div>

          <b-alert
            :show="isEntireFamily"
            variant="info"
            class="p-1"
          >
            <div class="d-flex">
              <feather-icon
                icon="InfoIcon"
                size="24"
                class="mr-1"
              />
              <small>{{ $t('messages.fund-divide-info') }}</small>
            </div>
          </b-alert>

          <!-- Wallet Family to transfer to -->
          <validation-provider
            #default="{ errors }"
            :name="isEntireFamily ? 'transfer to family' : 'transfer to'"
            rules="required"
          >
            <b-form-group
              :label="$t('Transfer To')"
              class="mb-2"
            >
              <treeselect
                v-model="getModel"
                placeholder="Select Account"
                :options="optionsForWalletFamily"
                :disable-branch-nodes="!isEntireFamily"
                :clearable="false"
                @select="node => getInputFromTree(node)"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, labelClassName }"
                  :class="labelClassName"
                >
                  <div
                    v-if="!node.isBranch"
                    v-b-tooltip.hover="isEntireFamily ? $t('messages.family-only-selection') : ''"
                  >
                    <i
                      :class="getWalletIcon(node.raw.type)"
                      class="fa"
                    />
                    {{ node.label }}
                  </div>
                  <span v-else>{{ node.label }}</span>
                </label>
              </treeselect>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between">
            <!-- Amount to Withdraw -->
            <validation-provider
              #default="{errors}"
              name="amount"
              class="px-0 col-5"
              :rules="`required|min_value:1|max_value:${wallet.balance}|wz-double`"
              :custom-messages="{ max_value: 'Insufficient balance' }"
            >
              <b-form-group :label="$t('Amount')">
                <b-input-group :prepend="$store.state.project.company.currency">
                  <b-form-input
                    v-model="form.amount"
                    type="number"
                    placeholder="Amount"
                    :disabled="action === 'deactivate'"
                    @keypress="handleAmountInput($event)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Transaction Date -->
            <b-form-group
              :label="$t('Transaction Date')"
              class="col-7"
            >
              <b-form-datepicker
                v-model="form.transactionDate"
                :max="new Date()"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                placeholder="Today"
              />
            </b-form-group>
          </div>

          <!-- Transfer Reason -->
          <b-form-group :label="$t('Transfer Narration')">
            <b-form-input
              v-model="form.description"
              placeholder="Transfer Narration"
            />
          </b-form-group>

          <!-- Document -->
          <invoice-uploader
            @document-uploaded="url => documentData = url"
            @document-removed="() => documentData = null"
          />
        </validation-observer>

        <!-- buttons -->
        <div class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            @click="() => { $emit('back') }"
          >
            {{ $t('Cancel') }}
          </b-button>

          <b-button
            variant="primary"
            @click="showConfirmModal"
          >
            <i class="fa fa-random" />
            {{ $t('Transfer') }}
          </b-button>
        </div>
      </b-overlay>
    </b-card>

    <confirm-modal
      modal="transfer-fund-modal"
      :title="`Transfer Fund From ${wallet.description}`"
      :description="`Are you sure you want to transfer ${formatAmount(Number(form.amount))} to ${destWalletName}?`"
      @confirm="transferFund"
    />
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BCard, BButton, BFormGroup, BFormInput, BOverlay, BAlert, BFormCheckbox, VBTooltip, BInputGroup, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import {
  required, max_value, min_value,
} from 'vee-validate/dist/rules'
import InvoiceUploader from '@/views/common/components/InvoiceUploader.vue'
import Treeselect from '@riophae/vue-treeselect'
import useWallet from '@/utils/wallet'
import ConfirmModal from '../reusables/ConfirmModal.vue'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const { getWalletIcon } = useWallet()

extend('wz-double', value => {
  if (typeof value !== 'number') {
    const decimals = value.split('.')[1]
    if (decimals && decimals.length > 2) return 'The amount should have atmost 2 decimal points'
  }
  return true
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BInputGroup,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    InvoiceUploader,
    ConfirmModal,
    Treeselect,
  },
  props: {
    walletUid: {
      type: String,
      default: () => '',
    },
    wallet: {
      type: Object,
      default: () => {},
    },
    availableFamily: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: () => false,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    action: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      max_value,
      min_value,
      getWalletIcon,
      form: {
        destWalletFam: null,
        destWallet: null,
        description: '',
        amount: null,
        transactionDate: null,
      },
      documentData: null,
      isProcessing: false,
      fetchingShares: false,
      availableWallets: [
        { label: 'Select an Account', uuid: null },
      ],
      transferOption: 'walletFam',
      isEntireFamily: false,
    }
  },
  computed: {
    destWalletName() {
      const selectedWallet = this.availableWallets.find(wallet => wallet.uuid === this.form.destWallet) ?? ''
      const walletFam = this.availableFamily.find(fam => fam.uuid === this.form.destWalletFam) ?? ''

      return `${walletFam.label} ${selectedWallet.uuid ? `(${selectedWallet.label})` : ''}`
    },
    getDestWalletFam() {
      return this.form.destWallerFam
    },
    getModel: {
      get() {
        return this.isEntireFamily ? this.form.destWalletFam : this.form.destWallet
      },
      set(value) {
        if (this.isEntireFamily) this.form.destWallerFam = value
        else this.form.destWallet = value
      },
    },
    optionsForWalletFamily() {
      let options = []
      if (!this.isEntireFamily) {
        const currentFam = this.availableFamily.find(wallet => wallet.uuid === this.walletUid && wallet.shares.length === 0)
        options = this.availableFamily.filter(family => family !== currentFam)
      } else options = this.availableFamily.filter(wallet => wallet.uuid !== this.walletUid && wallet.familyType !== 'OPENWALLET')

      return options.map(option => ({
        id: option.uuid,
        label: option.label,
        children: option.shares ? option.shares.map(share => ({
          id: share.uuid,
          label: share.description,
          isDisabled: this.isEntireFamily ? 1 : 0,
          type: share.wallet.__typename,
        })) : [],
      }))
    },
  },
  watch: {
    destWalletFam(val) {
      if (val) this.form.destWallet = null
    },
    isEntireFamily(val) {
      if (!val && this.availableWallets.length < 2 && this.form.destWalletFam) {
        this.getFamilyShares()
      } else {
        this.form.destWalletFam = null
      }
    },
    action: {
      handler(val) {
        if (val && this.wallet) this.form.amount = this.wallet.balance
      },
      immediate: true,
    },
  },
  methods: {
    handleAmountInput(event) {
      const inputValue = event.target.value
      const decimalParts = inputValue.split('.')

      if (decimalParts.length > 1 && decimalParts[1].length > 1) {
        event.preventDefault()
      }
      this.form.amount = event.target.value
    },
    getInputFromTree(node) {
      if (this.isEntireFamily) {
        this.form.destWalletFam = node.id
      } else {
        this.form.destWallet = node.id
        this.form.destWalletFam = this.availableFamily.find(wallet => wallet.shares.find(share => share.uuid === node.id)).uuid
      }
    },
    getFamilyShares() {
      if (!this.isEntireFamily) {
        this.fetchingShares = true
        let api = 'getWalletShares'
        const params = {
          walletUid: this.form.destWalletFam,
        }
        if (this.userType === 'company') {
          api = 'getCompanyWalletShares'
          params.companyUid = this.$store.state.project.selectedCompany
        }
        useApollo[this.userType][api](params).then(response => {
          let wallets = []
          if (this.userType === 'users') {
            wallets = response.data.me.wallets?.data[0]?.shares || []
          } else wallets = response.data.me.companies?.data[0]?.wallets?.data[0]?.shares || []
          wallets = wallets.filter(wallet => {
            if (wallet.uuid === this.wallet.uuid) return false
            if (wallet.owner?.isMe === true) return true
            if (wallet.shareDirection !== 'OUTBOUND') return true
            return true
          }).map(wallet => ({
            uuid: wallet.uuid,
            label: wallet.description,
          }))
          this.form.destWallet = null
          this.availableWallets = [
            { label: 'Select an Account', value: null },
            ...wallets,
          ]
        }).finally(() => { this.fetchingShares = false })
      }
    },
    showConfirmModal() {
      this.$refs.transferFundForm.validate().then(success => {
        if (success) {
          this.$bvModal.show('transfer-fund-modal')
        }
      })
    },
    transferFund() {
      this.isProcessing = true
      const params = {
        source: {
          walletUid: this.walletUid,
          shareUid: this.wallet.uuid,
        },
        input: {
          description: this.form.description,
          amount: Number(this.form.amount),
          ...(this.userType === 'company' ? { amountType: 'PAYABLE' } : []),
          transactionDate: this.form.transactionDate ? this.FORMAT_DATE(this.form.transactionDate, 'YYYY-MM-DD 00:00:00') : null,
        },
      }
      let api = 'makeWalletTransfer'

      if (this.userType === 'company') {
        params.companyUid = this.$store.state.project.selectedCompany
        api = 'makeCompanyInternalWalletTransfer'
      }

      if (this.isEntireFamily) {
        api = this.userType === 'company' ? 'makeCompanyAltFamilyTransfer' : 'makeAltFamilyTransfer'
        params.destinationWalletUid = this.form.destWalletFam
      } else {
        params.destination = {
          walletUid: this.form.destWalletFam,
          shareUid: this.form.destWallet,
        }
      }

      if (this.documentData) params.documentData = this.documentData

      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('transfer-success')
        this.isProcessing = false
      })
    },
    resetWallet() {
      if (this.isEntireFamily) this.form.destWallet = null
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/components/treeselect.scss";
</style>
