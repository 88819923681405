<template>
  <div>
    <!-- Show / Create Wallet -->
    <b-overlay
      :show="isLoading"
      class="px-2"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <!-- Show Wallet -->
      <show-wallets
        v-if="(isDefaultWallet && (wallets && wallets.shares) || $route.params.walletToSplit)"
        :wallets="wallets"
        :wallet-family="walletFamily"
        :share-types="shareTypes"
        :user-type="userType"
        :used-banks="usedBanks"
        :created-new-family="createdNewFamily"
        @refetch-wallet="getWallet"
        @wallet-updated="data => onWalletUpdate(data)"
        @create-wallet-fam="() => { isDefaultWallet = false }"
      />

      <!-- Inital Wallet Config -->
      <create-split-wallet
        v-else
        :user-type="userType"
        :is-default-wallet="isDefaultWallet"
        @wallet-config-success="() => { isDefaultWallet = true; createdNewFamily = true; getWallet() }"
        @back="() => { isDefaultWallet = true }"
      />
    </b-overlay>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BOverlay, VBTooltip } from 'bootstrap-vue'
import ShowWallets from './ShowWallets.vue'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'

export default {
  components: {
    BOverlay,
    ShowWallets,
    CreateSplitWallet,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      wallets: {
        shares: null,
      },
      walletFamily: [],
      shareTypes: [
        { text: this.$t('Alt Account'), value: 'InternalWallet' },
        { text: this.$t('Bank Account'), value: 'BankWallet' },
      ],
      isLoading: false,
      usedBanks: [],
      isDefaultWallet: this.$route.params.default ?? true,
      createdNewFamily: false,
    }
  },
  computed: {
    userType() {
      return this.$route.params.userType
    },
  },
  mounted() {
    if (this.userType) this.getWallet()
  },
  methods: {
    getWallet() {
      let api = 'getUserWallet'
      let params = {}
      let res = 'me'
      if (this.userType === 'company') {
        api = 'getCompanyWallet'
        params = { company: this.$store.state.project.selectedCompany }
        res = 'company'
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        this.wallets = response.data[res].wallet
        this.walletFamily = response.data[res].wallets.data
        if (this.wallets) {
          this.usedBanks = this.wallets.shares.filter(share => share.wallet.__typename === 'BankWallet').map(bankWallet => ({
            id: bankWallet.wallet.information.id,
            alias: bankWallet.wallet.information.alias,
          }))
        }
      }).finally(() => { this.isLoading = false })
    },
    onWalletUpdate({ alias, uuid, walletFam }) {
      this.walletFamily.find(fam => fam.uuid === walletFam).shares.find(wallet => wallet.uuid === uuid).description = alias
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
